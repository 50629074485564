<template>
  <base-section id="rewards">
    <!--<v-img :src="require('@/assets/roblox.png')" max-height="150" max-width="250"></v-img>-->
    <base-section-heading title="Enter Your Roblox Username">
    </base-section-heading>
    <v-container>
      <v-row align="center" justify="center">
        <v-col cols="11" md="8">
          <v-text-field  clearable label="Username" type="text" v-model="userName" style="font-size:18px;">
            <template v-slot:prepend>
              <v-fade-transition leave-absolute>                
                <img width="50" height="50" :src="require('@/assets/roblox.png')" alt="">
              </v-fade-transition>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" justify="space-around">
        <v-col cols="11" md="8">
          <v-btn color="primary" rounded block @click="getBalance">
            Check Rewards Amount
          </v-btn>
          <div class="text-h6 text-center">Your Reward Balance</div>
          <div class="text-center reward-amount" style="margin:-15px 0 -20px 0 !important;">
            {{ balance }}
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="11" md="8">
          <v-card class="mx-auto" max-width="400">
            <v-img class="white--text align-end" height="200px" :src="require('@/assets/sparks.png')">
              <!--<v-card-title>COMING SOON!</v-card-title>-->
              <v-chip class="ma-2 font-weight-bold" style="float:right;" color="primary">COMING SOON!</v-chip>
            </v-img>
            <v-card-subtitle class="pb-0">
              <div class="text-h4 pb-2">Sparks Sour Popping Candy</div>
            </v-card-subtitle>
            <v-card-text class="text--primary pb-2">
              <div class="text-h6">Available In 3 Awesome Flavors:</div>
              <div class="font-weight-light">
                <ul>
                  <li>BUSSIN BERRY!</li>
                  <li>WACKY WATERMELON</li>
                  <li>LIKE-IT LIME</li>
                </ul>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn block color="primary" disabled >
                REDEEM
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
import axios from 'axios';
export default {
  name: 'SectionProChart',

  data: () => ({
    features: [
      ['Components', 5, '40+'],
      ['Example Pages', 3, '10+'],
      ['Vue CLI Support', true, true],
      ['Bugfixes and Issues', false, true],
      ['6 Months Free Updates', false, true],
      ['Supports Vuetify', false, true],
      ['Price', 'Free', '$59'],
    ],
    balance:'',
    userName:''
  }),

  methods: {
    getBalance() {
      axios.post(`https://azurefx.azurewebsites.net/api/getPlayerAwardBalance?code=2wG2BgrB7VIJF-PgEQFUiFvsLv0oE0QvHdbAG9pUFqQkAzFuA01m9g==`, {
          name: this.userName
        })
        .then(response => {
            this.balance = response.data
            if(this.balance==''){this.balance = "0"}
            this.balance = this.balance + "pts";
        })
        .catch(e => {
          this.errors.push(e)
        })
    }
  }
}
</script>

<style>
.reward-amount{
    font-size:50px;
    font-weight: bold;
}
.chip-amount{
    font-size:25px !important;
}
</style>